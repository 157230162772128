
import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../GlobalContext';


const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { isRecording, audioManager, webSocketManager, isSpeechWebSocketConnected, isMetahumanWebSocketConnected, isThinking, setIsThinking, setIsRecording, addMessageHandler, removeMessageHandler } = useGlobalContext();
  const handleMessage = (message) => {
    webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "text": message }));
  };

  useEffect(() => {
    const handleMessage = async (event, type) => {
      console.log("handleMessage: Received message type: " + type)   
      if (type === 'metahuman') {
        const data = JSON.parse(event.data)
        if (data.text) {
          const botMessage = createChatBotMessage(data.text);

          setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
          }));
        }
      }   
    };

    // Assuming addMessageHandler and removeMessageHandler manage this handler
    addMessageHandler(handleMessage);
  
    return () => removeMessageHandler(handleMessage);
  }, [addMessageHandler, removeMessageHandler, setIsRecording]);

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {handleMessage},
        });
      })}
    </div>
  );
};

export default ActionProvider;