class WebSocketManager {
    constructor(speechAddress, metahumanAddress, onMessageCallback, onConnectionStatusChange) {
      this.speechWebSocket = null;
      this.metahumanWebSocket = null;
      this.speechAddress = speechAddress;
      this.metahumanAddress = metahumanAddress;
      this.onMessageCallback = onMessageCallback;
      this.onConnectionStatusChange = onConnectionStatusChange;
    }
  
    initWebSockets() {
      console.log("Speech WebSocket Initialization");
      this.initspeechWebSocket();
      console.log("Methahuman WebSocket Initialization");
      this.initmetahumanWebSocket();
    }
  
    initspeechWebSocket() {
      console.log('initspeechWebSocket');
      this.speechWebSocket = new WebSocket(this.speechAddress);
  
      this.speechWebSocket.onopen = () => {
        console.log("Speech WebSocket Opened");
        this.onConnectionStatusChange(true, 'speech');
        this.sendClientConfig();
      };
  
      this.speechWebSocket.onmessage = (event) => {
        this.onMessageCallback(event, 'speech');
      };
  
      this.speechWebSocket.onclose = (event) => {
        console.log("Speech WebSocket Closed:", event);
        this.onConnectionStatusChange(false, 'speech');
      };
    }
      
    initmetahumanWebSocket() {
      this.metahumanWebSocket = new WebSocket(this.metahumanAddress);

      this.metahumanWebSocket.onopen = () => {
        console.log("Metahuman WebSocket Opened");
        this.onConnectionStatusChange(true, 'metahuman');
      };

      this.metahumanWebSocket.onerror = (error) => {
        console.error("Metahuman WebSocket Error:", error);
      };


      this.metahumanWebSocket.onmessage = (event) => {
        this.onMessageCallback(event, 'metahuman');
      };

      this.metahumanWebSocket.onclose = (event) => {
        console.log("Metahuman WebSocket Closed:", event);
        this.onConnectionStatusChange(false, 'metahuman');
      };
    }
  
    sendClientConfig() {
      const clientConfig = {
        type: 'config',
        data: {
            language: null,
            processing_strategy: 'silence_at_end_of_chunk', 
            processing_args: {
                chunk_length_seconds: 3,
                chunk_offset_seconds: 0.1
            }
        }
      };
  
      if (this.speechWebSocket && this.speechWebSocket.readyState === WebSocket.OPEN) {
        this.speechWebSocket.send(JSON.stringify(clientConfig));
      }
    }
  
    sendAudioData(audioData) {
      if (this.speechWebSocket && this.speechWebSocket.readyState === WebSocket.OPEN) {
        this.speechWebSocket.send(audioData);
      }
    }
  
    sendMetahumanRequest(request) {
      if (this.metahumanWebSocket && this.metahumanWebSocket.readyState === WebSocket.OPEN) {
        this.metahumanWebSocket.send(request);
      }
    }
  
  }
  
  export default WebSocketManager;
  