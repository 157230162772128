import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import SqlEmployees from './pages/SqlEmployees';
import Projects from './pages/Projects';
import { GlobalProvider } from './GlobalContext';
import VideoPlayer from './pages/VideoPlayer';
import Conversational from './pages/Conversational'
import WebcamCapture from './pages/WebcamCapture';
import StableDiffusion from './pages/StableDiffusion';


import './App.css';// In index.js or App.js

// Import other necessary components and websockets initialization

function App() {
  console.log('App component mounted');
  return (
    <GlobalProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/sql_employees" element={<SqlEmployees />} />
            <Route path="/mina" element={<VideoPlayer videoUrl="images/mina_abbandono.mp4" />} />
            <Route path="/cattivone" element={<Conversational />} />
            <Route path="/llama2" element={<Conversational />} />
            <Route path="/inpainting" element={<WebcamCapture />} />
            <Route path="/stablediffusion" element={<StableDiffusion />} />
            {/* Define other routes for your project pages here */}
          </Routes>
        </Router>
    </GlobalProvider>
  );
}
export default App;
