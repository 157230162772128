import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormControl } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import LoadingSpinner from '../components/LoadingSpinner'; // Assume you have this component
import StatusIcons from '../components/StatusIcons';
import { useGlobalContext } from '../GlobalContext';
import TextInput from '../components/TextInput'; // Adjust the import path as necessary

import './StableDiffusion.css'; // Ensure you have this CSS file for styling


function StableDiffusion() {
  const { isRecording, audioManager, webSocketManager, isSpeechWebSocketConnected, isMetahumanWebSocketConnected, isThinking, setIsThinking, setIsRecording, addMessageHandler, removeMessageHandler } = useGlobalContext();
  const navigate = useNavigate(); // Declare the navigate function
  const [imageData, setImageData] = useState(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    // Fetch a random image from Unsplash based on a keyword
    // This uses the Unsplash Source API for simplicity
    const fetchBackgroundImage = async () => {
      const keyword = "ai"; // Example keyword
      const imageUrl = `https://source.unsplash.com/featured/?${keyword}`;
      setBackgroundImageUrl(imageUrl);
      setPageLoading(false);
    };

  }, []);


 const [audioRef, setAudioRef] = useState(null);

  useEffect(() => {
    console.log(isMetahumanWebSocketConnected);
    if (!isMetahumanWebSocketConnected) {
        navigate('/');
    } else {
        webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "application_start"}));
        setIsThinking(true);
        setIsRecording(false);
    }
  }, [isMetahumanWebSocketConnected, navigate]);

  const sendMessage = (text) => {
    if (audioRef) {
      audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
    }
    if (text.trim() !== '' && isMetahumanWebSocketConnected) {
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "text": text.trim() }));
      setIsThinking(true);
      setIsRecording(false);
    }
  };

  const navigateToProjects = () => {
    // Fade out the audio if it's currently playing
    if (audioRef) {
      audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "exit_application"}));
      // Optional: Add a slight delay to ensure fade out effect is heard before navigating
      setTimeout(() => {
        navigate('/projects');
      }, 500); // Adjust the timeout duration as needed
    } else {
      // If no audio is playing, navigate immediately
      navigate('/projects');
    }
  };

  useEffect(() => {
    const handleMessage = async (event, type) => {
      console.log("handleMessage: Received message type: " + type)
      if (type === 'metahuman') {
        const data = JSON.parse(event.data)
        if (data.speech) {
          // Stop or fade out the previous audio if it exists
          if (audioRef) {
            audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
          }

          // Directly work with the new audio source
          const newAudioRef = await audioManager.current.playAudio(data.speech,  () => { setIsRecording(true); });
          setAudioRef(newAudioRef); // Update state for future reference if needed
          setIsRecording(false);
        }
        if (data.image) {
          setImageData(data.image);
        }
        setIsThinking(false);
        setIsRecording(true);
      } 
    };

    // Assuming addMessageHandler and removeMessageHandler manage this handler
    addMessageHandler(handleMessage);
  
    return () => removeMessageHandler(handleMessage);
  }, [addMessageHandler, removeMessageHandler, setIsRecording]);



  return (
    <>
    <TextInput onReturnPressed={sendMessage}  />
    <Container fluid className="sql-employees-page">
    <Row>
      <Col md={12} className="text-center">
        {imageData && <img src={imageData} alt="Placeholder" className="img-fluid" />}
      </Col>
    </Row>

    <FontAwesomeIcon icon={faHome} size="2x" style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', cursor: 'pointer' }} onClick={navigateToProjects} />
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        {/* Since StatusIcons now relies on the global context, ensure it consumes the context to get its state */}
        <StatusIcons isRecording={isRecording} isMetahumanWebSocketConnected={isMetahumanWebSocketConnected} isSpeechWebSocketConnected={isSpeechWebSocketConnected} isThinking={isThinking} />
      </div>
    </Container>
    </>
  );
}
export default StableDiffusion;
