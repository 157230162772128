// src/components/DynamicInput.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import './TextInput.css'; // Ensure this CSS file exists with the necessary styles

const TextInput = ({ onReturnPressed, externalText }) => {
  const [text, setText] = useState('');
  const [visible, setVisible] = useState(false);
  const fadeOutTimer = useRef(null);

  const makeVisibleTemporarily = useCallback(() => {
    setVisible(true);
    clearTimeout(fadeOutTimer.current);
    fadeOutTimer.current = setTimeout(() => {
      setText('');
      setVisible(false);
    }, 3000); // Visible for 3 seconds
  }, []);

  useEffect(() => {
    if (externalText) {
      setText(externalText);
      makeVisibleTemporarily();
    }
  }, [externalText, makeVisibleTemporarily]);

  const handleKeyDown = useCallback((e) => {
    // Prevent default action for specific keys
    if (e.key === ' ' || e.key === 'Escape' || e.key === 'Enter') {
        e.preventDefault();
    }

    // Handle Backspace
    if (e.key === 'Backspace') {
        setText((prevText) => prevText.slice(0, -1));
        return;
    }

    // Hide and clear on Esc
    if (e.key === 'Escape') {
      setText('');
      setVisible(false);
      clearTimeout(fadeOutTimer.current);
      return;
    }

    // Filter out control keys and only process character keys
    const isCharacterKey = e.key.length === 1;
    if (!isCharacterKey) return;

    // Make input visible and update text
    setVisible(true);
    setText((prevText) => prevText + e.key);
    clearTimeout(fadeOutTimer.current);

    // Reset fadeOutTimer on each keystroke
    fadeOutTimer.current = setTimeout(() => {
      setText('');
      setVisible(false);
    }, 5000);
  }, []);

  // Clear text and trigger callback on Return
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === 'Enter') {
        onReturnPressed(text); // Trigger callback
        setText('');
        setVisible(false);
        clearTimeout(fadeOutTimer.current);
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [text, onReturnPressed]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearTimeout(fadeOutTimer.current);
    };
  }, [handleKeyDown]);

  return (
    <>
      {visible && <div className="overlay"></div>}
      <input
        className={`text-input ${visible ? 'visible' : ''}`}
        value={text}
        readOnly
      />
    </>
  );
};

export default TextInput;
