import Chatbot from 'react-chatbot-kit'
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'react-chatbot-kit/build/main.css'

import config from './TestBot/config.js';
import MessageParser from './TestBot/MessageParser.js';
import ActionProvider from './TestBot/ActionProvider.js';
import './TestBot/TestBot.css'

const Conversational = () => {
  return (
    <Container fluid>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </Container>
  );
};

export default Conversational;
