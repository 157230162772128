class AudioManager {
    constructor(bufferSize, outputSampleRate) {
      this.audioContext = null;
      this.processor = null;
      this.bufferSize = bufferSize;
      this.outputSampleRate = outputSampleRate;
      this.isRecording = false;
      this.stream = null;
      this.colvolver = null;
      this.speech_input = null;
    }
  
    async startRecording(processAudioCallback) {
      if (this.isRecording) return;
      this.isRecording = true;
  
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
  
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const input = this.audioContext.createMediaStreamSource(this.stream);
        this.processor = this.audioContext.createScriptProcessor(this.bufferSize, 1, 1);
  
        this.processor.onaudioprocess = processAudioCallback;
        input.connect(this.processor);
        this.processor.connect(this.audioContext.destination);
      } catch (error) {
        console.error('Error accessing microphone:', error);
        this.stopRecording();
      }
    }
    
    async initReverb(irUrl) {

      this.convolver = this.audioContext.createConvolver();
      const irResponse = await fetch(irUrl);
      const arrayBuffer = await irResponse.arrayBuffer();
      this.audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        this.convolver.buffer = buffer;
      });

      this.speech_input = this.audioContext.createGain();

      var gainDirect = this.audioContext.createGain()
      var gainReturn = this.audioContext.createGain()
      gainDirect.gain.setValueAtTime(0.8, this.audioContext.currentTime);
      gainReturn.gain.setValueAtTime(0.2, this.audioContext.currentTime);

      this.speech_input.connect(gainDirect);
      this.speech_input.connect(this.convolver);

      this.convolver.connect(gainReturn)

      gainDirect.connect(this.audioContext.destination);
      gainReturn.connect(this.audioContext.destination);  
    }
  

    async playAudio(base64Audio, callback) {
      const audioSrc = `data:audio/mp3;base64,${base64Audio}`;
      try {
        const response = await fetch(audioSrc);
        const arrayBuffer = await response.arrayBuffer();
        const decodedBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
  
        const source = this.audioContext.createBufferSource();
        source.buffer = decodedBuffer;
        const gainNode = this.audioContext.createGain();
        source.connect(gainNode);
        if (this.convolver) {
          gainNode.connect(this.speech_input);
        } else {
          gainNode.connect(this.audioContext.destination);
        }
        gainNode.gain.value = 1; // Start at full volume
        source.start(0);

        source.onended = () => {
          callback();
          console.log('Playback finished, isRecording set to true');
        };
  
        // Return a reference to the source and gainNode for external control
        return { source, gainNode };
      } catch (e) {
        console.error('Error with playing audio', e);
        return null;
      }
    }

    fadeOutSource(source, gainNode) {
      if (!source || !gainNode) return;
      const fadeDuration = 0.5; // Increase duration for smoother fade-out
      
      // Ensure the audio context is running
      if (this.audioContext.state !== 'running') {
        this.audioContext.resume().then(() => {
          console.log('AudioContext resumed successfully');
        });
      }
      
      // Ensure the gain value is set before ramping down
      gainNode.gain.setValueAtTime(1, this.audioContext.currentTime);
      
      // Use linear or exponential ramping to fade out
      gainNode.gain.linearRampToValueAtTime(0.001, this.audioContext.currentTime + fadeDuration);
      
      // Stop the source after fade-out is complete
      setTimeout(() => {
        source.stop();
      }, fadeDuration * 1000 + 100); // Adding a small buffer to ensure fade-out completes
    }
    

    stopRecording() {
      if (!this.isRecording) return;
      if (this.processor) {
        this.processor.disconnect();
        this.processor = null;
      }
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.stream = null;
      }
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
      this.isRecording = false;
    }
  
    downsampleBuffer(buffer, inputSampleRate) {
      if (inputSampleRate === this.outputSampleRate) {
          return buffer;
      }
      let sampleRateRatio = inputSampleRate / this.outputSampleRate;
      let newLength = Math.round(buffer.length / sampleRateRatio);
      let result = new Float32Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;
      while (offsetResult < result.length) {
          let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
          let accum = 0, count = 0;
          for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
              accum += buffer[i];
              count++;
          }
          result[offsetResult] = accum / count;
          offsetResult++;
          offsetBuffer = nextOffsetBuffer;
      }
      return result;
    }
    
    convertFloat32ToInt16(buffer) {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }
  }
  
  export default AudioManager;
  