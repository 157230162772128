import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../GlobalContext';
import './WebcamCapture.css'; // Make sure to define your CSS for .fullScreenImage

const WebcamCapture = () => {
  const { setIsRecording, webSocketManager} = useGlobalContext();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [countdown, setCountdown] = useState(3); // Countdown from 3 seconds
  const [isCountingDown, setIsCountingDown] = useState(false);

  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility

  useEffect(() => {
    let timer = null;
    if (isCountingDown && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      capture();
      setIsCountingDown(false);
      setCountdown(3); // Reset for next use
    }
    return () => clearTimeout(timer);
  }, [countdown, isCountingDown]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

    // Function to navigate back to the projects page
    const navigateToProjects = () => {
        setShowOverlay(true); // Begin fading to black
        setTimeout(() => {        
            navigate('/projects');
            setShowOverlay(false); // Hide overlay to be ready for next time
        }, 2000); // Adjust the timeout duration as needed
    webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "exit_application"}));
    };

  return (
    <Container fluid className="d-flex flex-column justify-content-center align-items-center h-100">
      {!imgSrc ? (
        <>
          <Webcam
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={{ width: 1280, height: 720, facingMode: "user" }}
          />
          <Button
            variant="primary"
            onClick={() => setIsCountingDown(true)}
            style={{ position: 'absolute', left: 0, bottom: 0 }}
          >
            {isCountingDown ? countdown : 'Take Picture'}
          </Button>
        </>
      ) : (
        <img src={imgSrc} alt="Captured" className="fullScreenImage"/>
      )}
      <FontAwesomeIcon icon={faHome} size="2x" style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', cursor: 'pointer' }} onClick={navigateToProjects} />
      <div className={`full-screen-overlay ${showOverlay ? 'show' : ''}`}></div>
    </Container>
  );
};

export default WebcamCapture;
