import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import LoadingSpinner from "../components/LoadingSpinner"; // Assume you have this component
import StatusIcons from "../components/StatusIcons";
import { useGlobalContext } from "../GlobalContext";
import TextInput from "../components/TextInput"; // Adjust the import path as necessary

import "./SqlEmployees.css"; // Ensure you have this CSS file for styling

function SqlEmployees() {
  const { isRecording, audioManager, webSocketManager, isSpeechWebSocketConnected, isMetahumanWebSocketConnected, isThinking, setIsThinking, setIsRecording, addMessageHandler, removeMessageHandler } = useGlobalContext();
  const navigate = useNavigate(); // Declare the navigate function
  const [imageData, setImageData] = useState(null);
  const [debugMessages, setDebugMessages] = useState(""); // Placeholder for debug messages
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility
  const [pageLoading, setPageLoading] = useState(true);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [externalText, setExternalText] = useState(""); // New state for managing external text

  useEffect(() => {
    // Fetch a random image from Unsplash based on a keyword
    // This uses the Unsplash Source API for simplicity
    const fetchBackgroundImage = async () => {
      const keyword = "office"; // Example keyword
      const imageUrl = `https://source.unsplash.com/featured/?${keyword}`;
      setBackgroundImageUrl(imageUrl);
    };

    fetchBackgroundImage();
  }, []);

  const [markdownContent, setMarkdownContent] = useState(`# Markdown Example
This is a markdown example. You can **emphasize** text or make it *italic*.

- List item 1
- List item 2

\`\`\`js
console.log('Hello, markdown!');
\`\`\`
`);

  // Determine column sizes based on the presence of imageData
  const imageColSize = imageData ? 8 : 0; // 0 if no image, else 8
  const markdownColSize = imageData ? 4 : 12; // 12 if no image, else 4

  const [audioRef, setAudioRef] = useState(null);

  useEffect(() => {
    console.log(isMetahumanWebSocketConnected);
    if (!isMetahumanWebSocketConnected) {
      navigate("/");
    } else {
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ command: "application_start" }));
      setIsThinking(true);
      setIsRecording(false);
    }
  }, [isMetahumanWebSocketConnected, navigate]);

  const sendMessage = (text) => {
    if (audioRef) {
      audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
    }
    if (text.trim() !== "" && isMetahumanWebSocketConnected) {
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ text: text.trim() }));
      setIsThinking(true);
      setIsRecording(false);
    }
  };

  const navigateToProjects = () => {
    // Fade out the audio if it's currently playing
    if (audioRef) {
      audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ command: "exit_application" }));
      // Optional: Add a slight delay to ensure fade out effect is heard before navigating
      setTimeout(() => {
        navigate("/projects");
      }, 500); // Adjust the timeout duration as needed
    } else {
      // If no audio is playing, navigate immediately
      navigate("/projects");
    }
  };

  useEffect(() => {
    const handleMessage = async (event, type) => {
      console.log("handleMessage: Received message type: " + type);
      if (type === "metahuman") {
        const data = JSON.parse(event.data);
        if (data.speech) {
          // Stop or fade out the previous audio if it exists
          if (audioRef) {
            audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
          }

          // Directly work with the new audio source
          const newAudioRef = await audioManager.current.playAudio(data.speech, () => {
            setIsRecording(true);
          });
          setAudioRef(newAudioRef); // Update state for future reference if needed
          setIsRecording(false);
        }
        if (data.text) {
          setMarkdownContent(data.text);
          setPageLoading(false);
        }
        if (data.command) {
          if (data.command == "exit_application") {
            console.log("Exit called");
          }
          return;
        }
        if (data.tool_action) {
          setDebugMessages(data.arguments["query"]);
        }
        if (data.image) {
          setImageData(data.image);
        }
        setIsThinking(false);
        setIsRecording(true);
      } else if (type === "speech") {
        const response = JSON.parse(event.data);
        if (response.language_probability > 0.9) {
          sendMessage(response.text);
          setExternalText(response.text);
        }
        console.log(response);
      }
    };

    // Assuming addMessageHandler and removeMessageHandler manage this handler
    addMessageHandler(handleMessage);

    return () => removeMessageHandler(handleMessage);
  }, [addMessageHandler, removeMessageHandler, setIsRecording]);

  return (
    <>
      <Container fluid className="sql-employees-page" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        {pageLoading && <LoadingSpinner />}
        <Row className="mb-3 p-3" style={{ alignItems: "center" }}>
          <Col xs={1}>
            <FontAwesomeIcon icon={faHome} size="2x" style={{ color: "white", cursor: "pointer" }} onClick={navigateToProjects} />
          </Col>
          <Col xs={11}>{/* Optionally place a title or leave empty */}</Col>
        </Row>
        <Row className="flex-grow-1 p-3">
          {/* Image and Markdown Window */}
          <Col md={imageColSize} className="text-center">
            {imageData && <img src={imageData} alt="Placeholder" className="img-fluid" style={{ width: "100%", height: "auto" }} />}
          </Col>
          <Col md={markdownColSize} className="markdown-window">
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
          </Col>
        </Row>

        {/* Debug Messages Area - Full width */}
        <Row className="p-3">
          <Col md={12}>
            <FormControl
              as="textarea"
              rows={3} // Adjust based on your needs
              value={debugMessages}
              className="debug-textarea mt-3"
              placeholder=""
            />
          </Col>
        </Row>
        <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1000 }}>
          {/* Since StatusIcons now relies on the global context, ensure it consumes the context to get its state */}
          <StatusIcons isRecording={isRecording} isMetahumanWebSocketConnected={isMetahumanWebSocketConnected} isSpeechWebSocketConnected={isSpeechWebSocketConnected} isThinking={isThinking} />
        </div>
      </Container>
      <TextInput onReturnPressed={sendMessage} externalText={externalText} />
    </>
  );
}
export default SqlEmployees;
