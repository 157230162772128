import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../GlobalContext';
import './LandingPage.css'; 

function LandingPage() {
  console.log('LandingPage component mounted');
  const { audioManager, processAudio } = useGlobalContext();
  const navigate = useNavigate(); // Declare the navigate function

  // Corrected function - no need to pass audioManager as a parameter
  const startAudio = async () => {
    if (audioManager.current) { // Access the current property of the ref
      await audioManager.current.startRecording(processAudio);

      // Initialize reverb here, use an appropriate IR file URL
      await audioManager.current.initReverb('impulse_responses/BatteryPowell.wav').then(() => {
        console.log('Reverb initialized');
      }).catch(console.error);

      navigate('/projects'); // Navigate after starting recording
    }
  };
  
  return (
    <div className="landing-page vh-100 d-flex flex-column justify-content-center align-items-center">
      <a href="https://www.iulmailab.it/dev/wp-content/uploads/2019/03/logo1-338x100.png" target="_blank" rel="noopener noreferrer" className="mb-4 logo-link">
        <img src="images/300-orizz-iulmailab.png" alt="Logo" className="logo-img"/>
      </a>
      <div className="typewriter">
        <h1 className="metahuman mb-4">metahuman</h1>
      </div>
      <Button variant="outline-secondary" className="start-button" onClick={startAudio}>Press to start</Button>
    </div>
  );
}

export default LandingPage;
