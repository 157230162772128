import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Projects.css';
import LoadingSpinner from '../components/LoadingSpinner'; // Assume you have this component
import StatusIcons from '../components/StatusIcons';
import { useGlobalContext } from '../GlobalContext';
import TextInput from '../components/TextInput'; // Adjust the import path as necessary

const apps = [
      {
        "type": "OPENAI_ASSISTANT",
        "name": "sql_employees",
        "keywords": ['database'],
        "description": "Esplora un Database SQL con l'AI",
        "image_url": "https://images.unsplash.com/photo-1448932223592-d1fc686e76ea?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=512&ixid=MnwxfDB8MXxyYW5kb218MHx8ZW1wbG95ZWVzfHx8fHx8MTcwOTAzMTg1NQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=512",
        "active": true
      },
      {
        "type": "PLACEHOLDER",
        "name": "mina",
        "keywords": ['mina'],
        "description": "Mina - Abban-dono (Video Ufficiale)",
        "image_url": "images/mina.png",
        "active": true
      },
      {
        "type": "OPENAI_CONVERSATIONAL",
        "name": "cattivone",
        "keywords": ['evil'],
        "description": "Fine Tuned GPT-3.5",
        "active": true
      },
      {
        "type": "OPENAI_CONVERSATIONAL",
        "name": "llama2",
        "keywords": ['llama'],
        "description": "LLama 2 Chatbot",
        "active": true
      },
      {
        "type": "PLACEHOLDER",
        "name": "inpainting",
        "keywords": ['photography'],
        "description": "Facciamo una Foto Insieme!",
        "active": true
      },
      {
        "type": "PLACEHOLDER",
        "name": "stablediffusion",
        "keywords": ['animation'],
        "description": "Come funziona Stable Diffusion?",
        "active": true
      },
      {
        "type": "PLACEHOLDER",
        "name": "design_thinking",
        "keywords": ['business', 'strategy'],
        "description": "Utilizzo dei Modelli di Linguaggio per la Strategia di Marketing",
        "active": false
      },
      {
        "type": "PLACEHOLDER",
        "name": "ai_emergence",
        "keywords": ['ai', 'flowers'],
        "description": "I Modelli di Linguaggio pensano veramente?",
        "active": false
      },
      {
        "type": "PLACEHOLDER",
        "name": "music",
        "keywords": ['music'],
        "description": "Crafting the soundtrack of the future",
        "active": false
      }
    ];
  

function Projects() {
  const { isRecording, audioManager, webSocketManager, isSpeechWebSocketConnected, isMetahumanWebSocketConnected, isThinking, setIsThinking, setIsRecording, addMessageHandler, removeMessageHandler } = useGlobalContext();
  const navigate = useNavigate(); // Declare the navigate function
  const [loadedImages, setLoadedImages] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [highlightedProject, setHighlightedProject] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility
  const [audioRef, setAudioRef] = useState(null);
  const [externalText, setExternalText] = useState(""); // New state for managing external text



  useEffect(() => {
    if (!isMetahumanWebSocketConnected) {
        navigate('/');
    } else {
        webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "homepage_start"}));
        setIsThinking(true);
        setIsRecording(false);
    }
  }, [isMetahumanWebSocketConnected, navigate]);

  const handleImageLoaded = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };

  // Function to generate a URL for Unsplash Source based on keywords
  const generateUnsplashUrl = (keywords) => {
    const keywordQuery = keywords.join(',');
    // Removed cacheBuster for stable URL generation
    return `https://source.unsplash.com/featured/512x512/?${keywordQuery}`;
  };

  // Check if all images are loaded
  useEffect(() => {
    console.log(loadedImages);
      if (loadedImages === apps.length) {
          setPageLoading(false);
      }
  }, [loadedImages]);

  const sendMessage = (text) => {
    if (text.trim() !== '' && isMetahumanWebSocketConnected) {
      webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "text": text.trim() }));
      setIsThinking(true);
      setIsRecording(false);
    }
  };


  useEffect(() => {
    const handleMessage = async (event, type) => {
      if (type === 'metahuman') {
        const data = JSON.parse(event.data)
        console.log("handleMessage: Received message type: " + type)
        if (data.speech) {
          // Stop or fade out the previous audio if it exists
          if (audioRef) {
            audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
          }

          // Directly work with the new audio source
          const newAudioRef = await audioManager.current.playAudio(data.speech, () => { setIsRecording(true); setIsThinking(false); });
          setAudioRef(newAudioRef); // Update state for future reference if needed
          setIsRecording(false);
        }
        // here we receive from metahuman the comman
        if (data.command) {
            if (data.command == "start_application") {
                handleProjectClick(data.application);
            }
            setIsThinking(false);
            setIsRecording(true);
        }
      } else if (type === 'speech') {
        const response = JSON.parse(event.data);
        if (response.language_probability > 0.9) {
          sendMessage(response.text);
          setExternalText(response.text);
        }
        console.log(response);
      }
    };

    // Assuming addMessageHandler and removeMessageHandler manage this handler
    addMessageHandler(handleMessage);
  
    return () => removeMessageHandler(handleMessage);
  }, [addMessageHandler, removeMessageHandler, setIsRecording]);

  const handleProjectClick = (projectName) => {
    setHighlightedProject(projectName);
    console.log(audioRef);
    if (audioRef) {
      audioManager.current.fadeOutSource(audioRef.source, audioRef.gainNode);
    }

    webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "homepage_run", "application": projectName }));
  
    // Wait for animation to complete before navigating
    setTimeout(() => {    
      setShowOverlay(true); // Begin fading to black
  
      // After the overlay has fully faded in, navigate to the new page
      setTimeout(() => {
          navigate(`/${projectName}`);
          setHighlightedProject(null); // Clean up state
          setShowOverlay(false); // Hide overlay to be ready for next time
      }, 1000); // This matches the CSS transition duration
    }, 5500); // Adjust time based on your animation duration
  };


  return (
    <Container className="projects-page">
    <TextInput onReturnPressed={sendMessage} externalText={externalText}  />
      {pageLoading && <LoadingSpinner />}
      <Row className="mt-5">
      {apps.map((project) => (
        <Col md={4} className={`mb-4 ${highlightedProject === project.name ? 'highlight' : (highlightedProject ? 'fade-out' : '')}`} key={project.name}>
          {/* Add the onClick handler to the Card component if it's active */}
          <div className={project.active ? 'card-enabled' : 'card-disabled'}>
            <Card className="h-100 shadow-sm bg-dark text-white text-center" onClick={() => project.active && handleProjectClick(project.name)}>
              <Card.Img variant="top" src={project.image_url || generateUnsplashUrl(project.keywords)} className="rounded-top vintage-image project-image" onLoad={handleImageLoaded} />
              <Card.ImgOverlay className="align-items-bottom d-flex">
                <Card.Text className="text-background">
                  {project.description}
                </Card.Text>
                {!project.active && <div className="coming-soon-overlay">Coming Soon</div>}
              </Card.ImgOverlay>
            </Card>
          </div>
        </Col>
      ))}

      </Row> 
      {/* Full-screen overlay */}
      <div className={`full-screen-overlay ${showOverlay ? 'show' : ''}`}></div>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        {/* Since StatusIcons now relies on the global context, ensure it consumes the context to get its state */}
        <StatusIcons isRecording={isRecording} isMetahumanWebSocketConnected={isMetahumanWebSocketConnected} isSpeechWebSocketConnected={isSpeechWebSocketConnected} isThinking={isThinking} />
      </div>
    </Container>
  );
}

export default Projects;
