import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../GlobalContext';
import './VideoPlayer.css';

const VideoPlayer = ({ videoUrl }) => {
  const { setIsRecording, webSocketManager} = useGlobalContext();
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility

  useEffect(() => {
    if (!videoUrl) {
      // Navigate back if no video URL is provided
      navigate('/projects');
      return;
    }

    setIsRecording(false);

    const video = videoRef.current;
    if (video) {
      video.play()
        .catch(error => console.error("Video play failed", error));
    }
  }, [videoUrl, navigate]);

  // Function to navigate back to the projects page
  const navigateToProjects = () => {
    setShowOverlay(true); // Begin fading to black
    setTimeout(() => {        
        navigate('/projects');
        setShowOverlay(false); // Hide overlay to be ready for next time
    }, 2000); // Adjust the timeout duration as needed
    webSocketManager.current.sendMetahumanRequest(JSON.stringify({ "command": "exit_application"}));
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0 }}>
      {/* Home Icon with absolute positioning */}
      <video ref={videoRef} src={videoUrl} controls autoPlay style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <FontAwesomeIcon icon={faHome} size="2x" style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', cursor: 'pointer' }} onClick={navigateToProjects} />
      <div className={`full-screen-overlay ${showOverlay ? 'show' : ''}`}></div>
    </div>
  );
};

export default VideoPlayer;
