import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faBroadcastTower, faS, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const StatusIcons = ({ isRecording, isMetahumanWebSocketConnected, isSpeechWebSocketConnected, isThinking }) => {
  const iconStyle = {
    fontSize: '24px',
    marginLeft: '10px',
    transition: 'color 0.3s ease' // Smooth transition for color change
  };

  return (
    <div>
      <FontAwesomeIcon icon={faMicrophone} style={{ ...iconStyle, color: isRecording ? 'white' : 'gray' }} />
      <FontAwesomeIcon icon={faBroadcastTower} style={{ ...iconStyle, color: isMetahumanWebSocketConnected ? 'white' : 'gray' }} />
      <FontAwesomeIcon icon={faS} style={{ ...iconStyle, color: isSpeechWebSocketConnected ? 'white' : 'gray' }} />
      <FontAwesomeIcon icon={faCircleNotch} spin={isThinking} style={{ ...iconStyle, color: isThinking ? 'white' : 'gray' }} />
    </div>
  );
};

export default StatusIcons;
