import React, { createContext, useContext, useState, useRef, useCallback, useEffect } from 'react';
import AudioManager from './AudioManager';
import WebSocketManager from './WebSocketManager';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [isThinking, setIsThinking] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeechWebSocketConnected, setIsSpeechWebSocketConnected] = useState(false);
  const [isMetahumanWebSocketConnected, setIsMetahumanWebSocketConnected] = useState(false);


  const isRecordingRef = useRef(isRecording);
  const isSpeechWebSocketConnectedRef = useRef(isSpeechWebSocketConnected);

  // Update refs whenever state changes
  useEffect(() => {
    isRecordingRef.current = isRecording;
    isSpeechWebSocketConnectedRef.current = isSpeechWebSocketConnected;
  }, [isRecording, isSpeechWebSocketConnected]);

  const audioManager = useRef(new AudioManager(4096, 16000));
  const webSocketManager = useRef(null);

  const handleWebSocketMessage = useCallback((event, type) => {
    handlersRef.current.forEach((handler) => handler(event, type));
  }, []); // Note: This dependency array is now empty.

  const handleWebSocketConnectionStatus = useCallback((isConnected, type) => {
    if (type === 'speech') {
      console.log("handleWebSocketConnectionStatus: speech websocket connected - " + isConnected);
      setIsSpeechWebSocketConnected(isConnected);
    } else if (type === 'metahuman') {
      console.log("handleWebSocketConnectionStatus: Metahuman Websocket Connected: " + isConnected);
      setIsMetahumanWebSocketConnected(isConnected);
    }
  }, []);

  useEffect(() => {
    console.log('GlobalProvider mounted');
    webSocketManager.current = new WebSocketManager(
      process.env.REACT_APP_WS_URL_SPEECH,
      process.env.REACT_APP_WS_URL_METAHUMAN,
      handleWebSocketMessage,
      handleWebSocketConnectionStatus
    );
    console.log('Initializing WebSockets...');
    webSocketManager.current.initWebSockets();

    // Cleanup function to close WebSocket connections
    return () => {
      //webSocketManager.current.closeConnections(); // Ensure your WebSocketManager has a method to close all connections
      console.log('webSocketManager.current.closeConnections')
    };
  }, []);


  const handlersRef = useRef([]);

  const addMessageHandler = useCallback((handler) => {
    console.log('Added message handler');
    handlersRef.current = [...handlersRef.current, handler];
  }, []);
  
  const removeMessageHandler = useCallback((handler) => {
    console.log('Removed message handler');
    handlersRef.current = handlersRef.current.filter((h) => h !== handler);
  }, []);

  // this is the link between the audioContext and the webSocket manager
  const processAudio = useCallback((e) => {
    if (!isRecordingRef.current || !isSpeechWebSocketConnectedRef.current) return;
    const left = e.inputBuffer.getChannelData(0);
    const downsampledBuffer = audioManager.current.downsampleBuffer(left, audioManager.current.audioContext.sampleRate);
    const audioData = audioManager.current.convertFloat32ToInt16(downsampledBuffer);
    webSocketManager.current.sendAudioData(audioData);
  }, [isRecording, isSpeechWebSocketConnected]);

  // Provide all the states, state updater functions, and methods in the context value
  return (
    <GlobalContext.Provider value={{
      isThinking,
      setIsThinking,
      isRecording,
      setIsRecording,
      isSpeechWebSocketConnected,
      setIsSpeechWebSocketConnected,
      isMetahumanWebSocketConnected,
      setIsMetahumanWebSocketConnected,
      audioManager,
      webSocketManager,
      addMessageHandler,
      removeMessageHandler,
      processAudio
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
